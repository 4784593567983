import React from "react";
import Carousel from "react-elastic-carousel";
import styled from 'styled-components';
import { images } from './data'

const Item = styled.div`
display: flex;
justify-content: center;
align-items: center;
height: 250px;
width: 100%;
background-color: #fff;
color: #fff;
margin: 0 15px;
font-size: 4em;
      `;

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 1200, itemsToShow: 4 },
];



const Brands = ()=>{
  return (
    <div  data-aos="zoom-in-up" style={{ marginTop:'5%' }}>
           <h1 className="CommonTitle"> OUR  BRANDS</h1>
      <div className="carousel-Container">
        <Carousel
         breakPoints={breakPoints}
         showArrows={true}
         pagination={false}
         
         >
         {images.map((item,i)=>{
            return(
              <Item>
          <img src={item} className='carousel-image'/>
          </Item>
            )
         })}
        </Carousel>
      </div>
    </div>
  );
}

export default Brands;