import React from 'react'
import Nav from '../components/Nav/nav'
import { ContactComponent } from '../components/Contact'

const Contact = ()=>{

        return(
            <div>
               <Nav />
               <div className='commonHeightContact'/>
               <ContactComponent />
            </div>
        )

}

export default Contact;