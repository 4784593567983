import React,{useEffect} from 'react'
import Aos from 'aos';
import 'aos/dist/aos.css';
import Header from '../../assets/images/header.jpg'
import { Button } from '../Common'


const HomeHeader = ()=>{

    useEffect(()=>{
        Aos.init({ duration:3000 });
     },[]);

    return(
        <div className='Mission-Container'>
               
               <div data-aos="fade-up" className='Sub-story-paragraph'>
                <h2>Mission</h2>
                 <p>
                 Having an organised supply chain monitoring <br/> over the best distribution channels to<br/> reach all demographic regions globally.
                 </p>
               </div>
               <div data-aos="fade-up" className='verticalLine'/>
               <div data-aos="fade-up" className='Sub-story-paragraph'>
                <h2>Vision</h2>
                 <p>
                Making, Values of healthy lifestyle compatible<br/> by sourcing what the country needs & Producing quality food using local organic ecosystem for the country's interests.
                 </p>
               </div>
        </div>
    )
}

export default HomeHeader;