import React,{useState} from 'react'
import styled from 'styled-components'
import RightNav from './rightNav';



const StyledBurger = styled.div`
    width: 2rem;
    display: none;
    position:fixed;
    height: 2rem;
    top: 15px;
    right: 20px;
    border-radius: 25px;
    justify-content: center; 
    align-items: center;
    text-align: center;
    @media(max-width:768px){
       display:flex;
        justify-content: space-around;
        flex-flow: column nowrap;
        float:right;
        z-index:99;
     }
     div{
        width: 2rem;
        height: 0.25rem;
        background-color: ${({open})=>open ?"#fff":"#7aab00"};
        border-radius:10px;
        transform-origin:1px;
        transition: all 0.5s linear;

        &:nth-child(1){
          transform: ${({open})=>open ?'rotate(45deg)':'rotate(0)'};
        }
        &:nth-child(2){
          transform: ${({open})=>open ?'translateX(100%)':'translateX(0)'};
          opacity: ${({open})=>open ? 0 : 1 };
        }
        &:nth-child(3){
          transform: ${({open})=>open ?'rotate(-45deg)':'rotate(0)'};
        }
     }`;

const Burger = ({scrollToPage})=>{  
    const[open, setOpen] = useState(false);

    return(
       <>
          <StyledBurger open={open} onClick={()=>setOpen(!open)}>
          <div />
          <div />
          <div />
          </StyledBurger>
          <RightNav open={open} scrollToPage={scrollToPage}/>
       </>
    );
}

export default Burger;