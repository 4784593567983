import React,{useEffect} from 'react'
import styled from 'styled-components';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useNavigate } from "react-router-dom";
import Header from '../../assets/images/04.jpg'
import { Button } from '../Common'



const HomeHeader = ()=>{
    useEffect(()=>{
        Aos.init({ duration:5000 });
       
     },[]);

    
const navigate = useNavigate(); 
 const RouteChange = () =>{

      navigate('/Product');
    }

   

    return(
    <>
        <div className='Home-Header-Container'>
        

        <div  className='imageView'>
        <img src={Header} className='Header-logo'/>
        </div>
         
     </div>
      <div className='First-Box'>
      <div  className='Sub-story-paragraph'>
             <h1>Gourmand’s Best</h1>
             <div className='sub-row'>
             <h1>Cooking</h1> 
             <h1 className='fonth1' >Experience</h1>
             </div>
              <p>
              Groot has an exceptional mind for quality & taste, 
Only the best products are sourced by Groot, 
Products & ingredients which bring out the flavors 
of Srilanka.

              </p>
              <div 
        className="HomeButton" 
        onClick={RouteChange}
        ><p className='HomeButton-text'>Go to products</p>
        </div>
            </div>
      </div>
    </>
    )
}

export default HomeHeader;

{/* <Button 
children='Go to groot project'
background='linear-gradient(to right ,#7aab00,#4d5a0)'
/> */}