import React,{useEffect} from 'react'
import Aos from 'aos';
import 'aos/dist/aos.css';


const Button = ({ onClick, children, Width,Margintop,Height,Color,BorderRadius,background })=>{

    useEffect(()=>{
        Aos.init({ duration:3000 });
     },[]);

    return(
        <div 
        className="commonButton" 
        onClick={onClick} 
        style={{width:Width,marginTop:Margintop,color:Color,height:Height,borderRadius:BorderRadius}} 
        ><p className='commonButton-text'>{children}</p>
        </div>
    )
}

export default Button;


