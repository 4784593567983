import React,{useEffect} from 'react'
import Aos from 'aos';
import 'aos/dist/aos.css';
import Header from '../../assets/images/header.jpg'
import { Button } from '../Common'
import { useNavigate } from "react-router-dom";


const HomeHeader = ()=>{

    useEffect(()=>{
       
        Aos.init({ duration:3000 });
     },[]);

     const navigate = useNavigate(); 
     const RouteChange = () =>{
          navigate('/Story');
        }
     
    return(
        <div className='Sub-story-Container'>
               <div data-aos="zoom-in-up">
               <img src={Header} className='Sub-story-Image'/>
               </div>
               <div data-aos="zoom-in-up" className='Sub-story-paragraph'>
                <h2>OUR STORY</h2>
                 <p>
                 Groot International (Pvt) Ltd is a limited liability company since 2007, Under former company Yus Exim (Pvt) Ltd., Marketing our 
                own brands <b>“KINGFISHER”</b>,<b>“GROOT”</b> & <b>“KRISH”</b>. The current Directors of Groot International (Pvt) Ltd are, Mr. K.Thiyageshwaran
                ith more than 20 years of marketing experience and Mr. A.Sivakumar with more than 32 years of experience in FMCG. Both are 
                qualified to manage the company in commercial and marketing operations. Their experience has taken the company in to new 
                heights .
                 </p>
                 <Button
                  children='Read more'
                  Width='100px'
                  onClick={RouteChange}
                 />
               </div>
        </div>
    )
}

export default HomeHeader;