import React from 'react'
import Nav from '../components/Nav/nav'
import { OurStory,Brands  } from '../components/Story'
import { Footer } from '../components/Common'

const Story = ()=>{

        return(
            <div>
               <Nav />
               <div className='commonHeight'/>
               <OurStory  />
               <Footer />
            </div>
        )

}

export default Story;