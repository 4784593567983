import React from 'react'
import Nav from '../components/Nav/nav'
import { HomeHeader,Story,Brands,Product,Header } from '../components/Home'
import { Footer } from '../components/Common'

const Home = ()=>{

        return(
            <div>
               <Nav />
               <Header />
               <Story />
               <Brands />
               <Product />
               <Footer />
            </div>
        )

}

export default Home;