import React,{useEffect,useState} from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faHome,faInfoCircle,faAddressCard,faTh} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
import Aos from 'aos';
import 'aos/dist/aos.css';


const Ul = styled.ul `
     @media(max-width:768px){
        transform: ${({open}) => open ?'translateX(0)':'translateX(100%)'};
        transition: transform 0.3s ease-in-out;
     }
`;

const RightNav = ({open})=>{
    useEffect(()=>{
        Aos.init({ duration:3000 });
     },[]);

     const [navbar,setnavbar]=useState(false);

     const Scrollings = ()=>{
        if(window.scrollY >= 55){
             setnavbar(true);
        }else{
           setnavbar(false);
        }
     }
  
     window.addEventListener('scroll',Scrollings);


     const navigate = useNavigate(); 
     const naviagtePageTo = (path) =>{
          navigate(path);
        }
   
    

    return(
      <div>
            {navbar?
            <div>
                <Ul data-aos="fade-down" className="Rightnavi" open={open}>
           <div onClick={()=>naviagtePageTo('/')}>Home</div>
           <div onClick={()=>naviagtePageTo('/Product')}>Products</div>
           <div onClick={()=>naviagtePageTo('/Story')}>Story</div>
           <div onClick={()=>naviagtePageTo('/Contact')}>Contact</div>
       </Ul>
            </div>
            :<div>
                <Ul data-aos="fade-right" className="Header-Option" open={open}>
                <div onClick={()=>naviagtePageTo('/')}>Home</div>
                <div onClick={()=>naviagtePageTo('/Product')}>Products</div>
            </Ul>
                <Ul data-aos="fade-left" className="Down-Header-Option" open={open}>
                  <div onClick={()=>naviagtePageTo('/Story')}>Story</div>
                <div onClick={()=>naviagtePageTo('/Contact')}>Contact</div>
            </Ul></div>}
       <Ul className="Rightnav2" open={open}>
              <div className="user-options">
               <div onClick={()=>naviagtePageTo('/')}><FontAwesomeIcon icon={faHome}/>&nbsp;&nbsp; Home</div>
               <div onClick={()=>naviagtePageTo('/Product')}><FontAwesomeIcon icon={faTh}/>&nbsp;&nbsp;Product</div>
               <div onClick={()=>naviagtePageTo('/Story')}><FontAwesomeIcon icon={faAddressCard}/>&nbsp;&nbsp;Story</div>
               <div onClick={()=>naviagtePageTo('/Contact')}><FontAwesomeIcon icon={faInfoCircle}/>&nbsp;&nbsp;Contact</div>
              </div>
       </Ul>
      </div>
    );
}

export default RightNav;