import React from "react";
import Carousel from "react-elastic-carousel";
import styled from 'styled-components';
import Brand1 from '../../assets/images/brand1.png'
import Brand2 from '../../assets/images/brand2.png'
import Brand3 from '../../assets/images/brand3.png'
import Brand4 from '../../assets/images/brand4.png'
import Brand5 from '../../assets/images/brand5.png'

const Item = styled.div`
display: flex;
justify-content: center;
align-items: center;
height: 200px;
width: 70%;
margin-top:10;
background-color: #fff;
color: #fff;
margin: 5px 8px;
box-shadow: 0px 6px 16px 0px rgba(0, 0, 0,0.3);
font-size: 4em;
border-radius:18px
      `;

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 1200, itemsToShow: 4 },
];

const images = [
  Brand1,
  Brand2,
  Brand3,
  Brand4,
  Brand5,
]

const Brands = ()=>{
  return (
    <div style={{ marginTop:'8%' }}>
      {/* <h1 style={{ textAlign: "center" }}>OUR BRANDS</h1> */}
      <div className="story-carousel-Container">
        <Carousel
        itemsToShow={5}
         breakPoints={breakPoints}
         showArrows={false}
         pagination={false}
         >
         {images.map((item,i)=>{
            return(
              <Item>
          <img src={item} className='story-carousel-image'/>
          </Item>
            )
         })}
        </Carousel>
      </div>
    </div>
  );
}

export default Brands;