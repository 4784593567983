import React,{useEffect} from 'react';
import Tlogo from '../../assets/images/t-logo.png'
import insta from '../../assets/images/instagram.png'
import fb from '../../assets/images/facebook.png'
import mail from '../../assets/images/gmail.png'
import Aos from 'aos';
import 'aos/dist/aos.css';
  

const Footer = ()=>{
    useEffect(()=>{
        Aos.init({ duration:3000 });
     },[]);
    return(
        <div data-aos="fade-up">
          
          <div className='Footer-Container'>
          <div className="Footer-box">
           <img src={Tlogo} width="120px" height="70px" alt="logo" />
           <p>We would love to engage in business with you 
                directly or indirectly through quality food products 
                you consume each day.</p>
         
          </div>
         <div className="second-box-main">
         <div className="Second-box">
        <div className='second-text'>
        <h2>Address</h2>
           <p>340/35 K.Cyril C Perera Mawatha,
              Colombo 13.
              Sri – Lanka</p>
        </div>
          </div>
          <div className='second-box-social'>
               <a href="https://www.instagram.com/"><img src={insta} width="38px" style={{boxShadow:'0px 5px 10px 0px rgba(0, 0, 0,0.3)',borderRadius:50}}  height="38px" alt="insta"/></a>
                <a href="https://www.facebook.com/"><img src={fb} width="38px" style={{marginLeft:15,boxShadow:'0px 5px 10px 0px rgba(0, 0, 0,0.3)',borderRadius:50}} height="38px" alt="fb"/></a>
                <a href="https://mail.google.com/mail/u/0/?tab=rm&ogbl#inbox?compose=DmwnWrRpdlrBqPRgcDvmwlkhfkgwLmRlqzBSCfKKmxkVrpZCXqSsvlpjWzsRhQFNLCkWvhSDPxsB"><img src={mail} width="38px" style={{marginLeft:15,boxShadow:'0px 5px 10px 0px rgba(0, 0, 0,0.3)',borderRadius:50}} height="38px" alt="tw"/></a>
          </div>
         </div>
              </div>  
              <div className='footer-copy-right-view'>
                  <p>Copyright © 2022 Groot (Pvt) Ltd. All Rights Reserved.</p>
              </div>
        </div>

    );
}

export default Footer;