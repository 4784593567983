import React,{useEffect} from "react";
import { ProductCard } from './index'
import krish from '../../assets/images/krish.png'
import groot from '../../assets/images/logo.png'
import Mahajbeen from '../../assets/images/Mahajbeen.png'
import ruchi from '../../assets/images/ruchi.png'
import Aos from 'aos';
import 'aos/dist/aos.css';
import { oilBrands,riceBrands,ingredientBrands } from './data'




const Products = ()=>{
    useEffect(()=>{
        Aos.init({ duration:3000 });
     },[]);
  return (
    <div style={{ marginTop:'8%',width:'100%',alignItems:'center' }}>
      <div className="product-web-row">
         <img src={groot} alt="profile" className="product-logo-groot" />
      <h1 data-aos="fade-up" className="CommonTitle">OIL</h1>
      <img src={krish} alt="profile" className="product-logo-krish" />
      </div>
    <div data-aos="fade-up" className="card-parent-main-view">
    <div className="card-main-view">
      {oilBrands.map((item,i)=>{
          return(
                <ProductCard 
            image={item.image}
            name={item.name}
            count={item.count}
          />
          )
       })}
       </div>
    </div>
    <div className='commonHeight'/>
    <div className="product-web-row">
         <img src={Mahajbeen} alt="profile" className="product-logo-groot" />
      <h1 data-aos="fade-up" className="CommonTitle">Rice</h1>
      <img src={ruchi} alt="profile" className="product-logo-krish" />
      </div>
    <div data-aos="fade-up" className="card-parent-main-view">
    <div className="card-main-view">
      {riceBrands.map((item,i)=>{
          return(
         
                <ProductCard 
            image={item.image}
            name={item.name}
            count={item.count}
          />
         
          )
       })}
       </div>
    </div>

    <div className='commonHeight'/>
    <div className="product-web-row">
         {/* <img src={groot} alt="profile" className="product-logo-groot" /> */}
      <h1 data-aos="fade-up" className="CommonTitle"> INGREDIENTS</h1>
      {/* <img src={krish} alt="profile" className="product-logo-krish" /> */}
      </div>
    <div data-aos="fade-up" className="card-parent-main-view">
    <div className="card-main-view">
      {ingredientBrands.map((item,i)=>{
          return(
         
                <ProductCard 
            image={item.image}
            name={item.name}
            count={item.count}
          />
         
          )
       })}
       </div>
    </div>
    <div className='commonHeight'/>
    </div>
  );
}

export default Products;