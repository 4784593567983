import React from 'react';
import Tlogo from '../../assets/images/t-logo.png'
import insta from '../../assets/images/instagram.png'
import fb from '../../assets/images/facebook.png'
import mail from '../../assets/images/gmail.png'

const ContactComponent = ()=>{
    return(
        <div>
          
          <div className='Contact-design-Container'>
          <div className="Footer-box">
           <h2>Address</h2>
           <p>340/35 K.Cyril C Perera Mawatha,
              Colombo 13.
              Sri – Lanka.
              </p>
         
          </div>
         <div className="second-box-main">
         <div className="Second-box">
        <div className='second-text'>
        <h2>Open Time</h2>
           <p>Mon - Fri (8.30 AM - 5.30 PM)</p>
           <p>Sat (8.30 AM - 1.30 PM)</p>
        </div>
          </div>
         </div>
              </div>  

              <div className='Contact-design-Container'>
          <div className="Footer-box">
           <h2>Email</h2>
           <p>grootinter@gmail.com</p>
         
          </div>
         <div className="second-box-main">
         <div className="Second-box">
        <div className='second-text'>
        <h2>Phone</h2>
           <p>011 7200500</p>
           <p>011 2472910 ( FAX )</p>
        </div>
          </div>
         
         </div>
              </div> 
              <div style={{ height:'100px' }}/>
              <div className='Contact-copy-right-view'>
              <div className='second-box-social'>
               <a href="https://www.instagram.com/"><img src={insta} width="38px" style={{boxShadow:'0px 5px 10px 0px rgba(0, 0, 0,0.3)',borderRadius:50}}  height="38px" alt="insta"/></a>
                <a href="https://www.facebook.com/"><img src={fb} width="38px" style={{marginLeft:15,boxShadow:'0px 5px 10px 0px rgba(0, 0, 0,0.3)',borderRadius:50}} height="38px" alt="fb"/></a>
                <a href="https://mail.google.com/mail/u/0/?tab=rm&ogbl#inbox?compose=DmwnWrRpdlrBqPRgcDvmwlkhfkgwLmRlqzBSCfKKmxkVrpZCXqSsvlpjWzsRhQFNLCkWvhSDPxsB"><img src={mail} width="38px" style={{marginLeft:15,boxShadow:'0px 5px 10px 0px rgba(0, 0, 0,0.3)',borderRadius:50}} height="38px" alt="tw"/></a>
          </div>
                  <p>Copyright © 2020 Groot (Pvt) Ltd. All Rights Reserved.</p>
              </div>
        </div>

    );
}

export default ContactComponent;