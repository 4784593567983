import React,{useEffect} from 'react'
import Aos from 'aos';
import 'aos/dist/aos.css';
import Header from '../../assets/images/header.jpg'
import Lottie from 'lottie-react-web'
import storyLottie from '../../assets/json/story.json'


const OurStory = ()=>{

    useEffect(()=>{
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
        Aos.init({ duration:3000 });
     },[]);

    return(
        <div className='Our-story-Container'>

               <div className='Sub-Our-story-paragraph'>
       
               <img data-aos="zoom-in-up" src={Header} className='Our-Sub-story-Image'/>
               <div className='littleHeight' />
               <div className='littleHeight' />
               <div >
               <div className='littleHeight' />
               <h2>OUR STORY</h2>
                 <p>
                 Groot International (Pvt) Ltd is a limited liability company since 2007, Under former company Yus Exim (Pvt) Ltd., Marketing our 
own brands <b>“KINGFISHER”</b>,<b>“GROOT”</b> & <b>“KRISH”</b>. The current Directors of Groot International (Pvt) Ltd are, Mr. K.Thiyageshwaran
ith more than 20 years of marketing experience and Mr. A.Sivakumar with more than 32 years of experience in FMCG. Both are 
qualified to manage the company in commercial and marketing operations. Their experience has taken the company in to new 
heights .
                 </p>
                 <div className='littleHeight' />
              
                 <h2>SESAME MANUFACTURING UNIT</h2>
                 <p>
                 We have a state of the art Cold press Sesame Oil Manufacturing unit & Steam based extrusion type oil extraction Manufacturing
unit.
                 </p>
                 <p>
                 We have also installed state of the art seed cleaning machine, First of its kind in Srilanka.
                 </p>
             

                
                 </div>
               </div>


               <div className='Sub-Our-story-paragraph'>
                 <h2>PROFILE</h2>
                 <p>
                 Groot Intertnational originally was an importer/distributor of a wide range of edible oil products and food items in Sri Lanka.
Our suppliers range from Asia to South East Asian Regions, where we Import and Distribute Palm Olein, Coconut Oil, Sun Flower 
Oil, Corn Oil ,Biriyani Rice, Basmati Rice, Sodium Bicarbonate, Liquid Glucose, et
                 </p>
                 <p>
                 Now, Groot has expanded into manufacture and distribution of Roasted Sesame Oil , Pure Sesame Oil and Virgin Coconut Oil 
packed in glass bottles under the brand “Groot“. Also, Economically packed in pet bottles under the name of “KRISH” to cater 
the local market.<br/>
We are importing Basmati Rice under Mehjabeen & Ruchi Brand.

                 </p>
                 <p>
                 As Groot International has much emphasis over quality, We are well recognized in the export world, Exporting Virgin Coconut 
oil, Sesame Seed, Walnuts & Sesame oil to all parts of the globe.
                 </p>
                 <div className='littleHeight' />
                 <h2>OUR STRENTH</h2>
                 <p>
                 Understanding & catering the needs of the customer.<br/>
                  Strong Trademarks.<br/>
                  Wide & well-defined distribution network.<br/>
                  Large storage with over 20,000 square feet of storing facility and<br/>
                  100,000 litres oil Storage facility.<br/>
                  Skilled workforce.<br/>
                  Good competitive prices.
                 </p>
                 <div className='littleHeight' />
                 <h2>OUR EXPORTS</h2>
                 <p>
                 We are Exporting Virgin Coconut oil, Sesame Seed, Walnuts & sesmae oil
                  to all parts of the globe
                 </p>
                 
                 <div className='littleHeight' />
                 <h2>FUTURE PLAN</h2>
                 <p>
                  <b>We are planning to import : </b> 
                  Citric Acid, Ascorbic Acid, Sodium Met sulfate, Soya Lesathin,
                  Liquid Maltose, Modify Starch from China, Indonesia, Malaysia, Bangkok
                  Corn Grid, food coloring, high quality Basmati Rice from India, Pakistan.
                  Scheming to Export Areca nut to India and Virgin Coconut
                  Oil to Europe and American Countries.
                 </p> 

               </div>
        </div>
    )
}

export default OurStory;