import React from "react";
import Header from '../../assets/images/header.jpg'


const Products = ({ image, name,count }) => {
    console.log('count ==> ', count)
    return (
        <div className="web-content-container">
            <div className="web-content">
                <div className="web-image-view">
                    <img src={image} alt="profile" className="web-image" />
                </div>
                <div className="web-content-text">
                    <p>{name}</p>
                    <p>{count} </p>
                </div>
            </div>
        </div>
    );
}

export default Products;