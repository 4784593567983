export const oilBrands = [
    {
        image:require('../../assets/images/1.jpg'),
        name:`Groot Pure Sesame Oil - 200 ml`,
        count:'50 per case'
    },
    {
       image:require('../../assets/images/2.jpg'),
       name:'Groot Glass Pure Sesame Oil  - 500 ml',
       count:'24 per case'
   },
     {
       image:require('../../assets/images/6.jpg'),
       name:'Krish Pet Pure Sesame Oil  - 500 ml',
       count:'24 per case'
   },
   {
       image:require('../../assets/images/3.jpg'),
       name:'Krish Pet Pure Sesame Oil  - 1 ltr',
       count:'12 per case'
   },
    {
     image:require('../../assets/images/7.jpg'),
     name:'Groot Roasted Sesame Oil - 200 ml',
     count:'50 per case'
   },
   {
    image:require('../../assets/images/10.jpg'),
    name:'Groot Glass Roasted Sesame Oil  - 500 ml',
    count:'24 per case'
   },
   {
    image:require('../../assets/images/9.jpg'),
    name:'Krish Pet Roasted Sesame Oil  - 500 ml',
    count:'24 per case'
   },
  
   {
       image:require('../../assets/images/5.jpg'),
       name:'Krish Pet Roasted Sesame Oil  - 1 ltr',
       count:'12 per case'
   },
   {
    image:require('../../assets/images/8.jpg'),
    name:'Krish Pet Pure Sesame Oil  - 5 ltr',
    count:'4 per case'
   },
   {
    image:require('../../assets/images/4.jpg'),
    name:'Krish Pet Roasted Sesame Oil  - 5 ltr',
    count:'4 per case'
},
   ]
   
   export  const riceBrands = [
     {
         image:require('../../assets/images/empty.jpg'),
         name:'Mehjabeen Basmati Rice',
         count:'1 kg - 12 per case'
     },
     {
        image:require('../../assets/images/empty.jpg'),
        name:'Mehjabeen Basmati Rice',
        count:'5 kg - 4 per case'
    },
    {
        image:require('../../assets/images/empty.jpg'),
        name:'Ruchi Basmati Rice',
        count:'1 kg - 4 per case'
    },
    {
        image:require('../../assets/images/empty.jpg'),
        name:'Ruchi Basmati Rice',
        count:'5 kg - 4 per case'
    },
    ]
   
    export   const ingredientBrands = [
      {
        image:require('../../assets/images/empty.jpg'),
        name:' Sodium Bicarbonate',
        count:'25 kg per bag'
       },
       {
           image:require('../../assets/images/empty.jpg'),
           name:' Liquid Glucose',
             count:'300 kg per Drum'
       },
       
    ]