import React from 'react'
import "./styles/index.scss"
import { Route,Routes } from 'react-router-dom'
import { Home,Product,Contact,Story } from '../src/screens'

function App() {
  return (
    <Routes>
       <Route element={<Home />} exact path='/'/>
       <Route element={<Product/>} exact path='/Product'/>
       <Route element={<Contact/>} exact path='/Contact'/>
       <Route element={<Story/>}  exact path='/Story'/>
    </Routes>
  );
}

export default App;