import React from 'react'
import Nav from '../components/Nav/nav'
import { Products } from '../components/product'
import { Footer } from '../components/Common'

const Product = ()=>{
        return(
            <div>
               <Nav />
               <div className='commonLowHeight'/>
               <Products />
               <Footer />
            </div>
        )
}

export default Product;